import { navigate } from "gatsby";
import React from "react"
import GradientButton from "../components/gradient-button";

import Layout from "../components/layout"
import SEO from "../components/seo";
import NotFound from '../assets/images/404/404.svg';

const NotFoundPage = () => {
  const onClick = () => {
    navigate('/')
  }
  return (
    <Layout>
      <SEO title="404: Page introuvable" />
      <main className="jumbotron jumbotron-block full theme-jumbotron__default page-404 text-center bg-transparent">
        <div className="container">
          <div className="row">
            <div className="col">
              <img src={NotFound} alt="404 not found" />
              <h1 className='text-white h3 font-weight-bold my-4'>Oops! Cette page n'existe plus.</h1>
              <GradientButton type='button' onClick={onClick} className='w-auto px-3'>
                Mene moi vers la page d'accueil
              </GradientButton>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default NotFoundPage
